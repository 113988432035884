<template>
  <div class="dokumente">
    <b-row>
      <b-col>
        <h1>Dokumente</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h2>Auftragsformulare</h2>
        <h3>Privatkunden</h3>
        <b-table striped hover :items="itemsPK" :fields="fields">
          <template v-slot:cell(file)="data">
            <b-button :href="data.value" target="_blank" variant="outline-primary">Download</b-button>
          </template>
        </b-table>
        <h3>Gewerbekunden</h3>
        <b-table striped hover :items="itemsPK" :fields="fields">
          <template v-slot:cell(file)="data">
            <b-button :href="data.value" target="_blank" variant="outline-primary">Download</b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import Vue from 'vue'
  import {ButtonPlugin, TablePlugin} from 'bootstrap-vue'
  import { Energiearten } from '../../../common/constants'

  Vue.use(ButtonPlugin)
  Vue.use(TablePlugin)

  export default {
    name: 'Dokumente',
    data() {
      return {
        Energiearten: Energiearten,
        itemsPK: [
          { typ: Energiearten.Strom, title: '19006_Strom-PK_Kombi_Easy24 Natur PUR+Natur12 BEST.pdf', file: '/dokumente/pk/19006_Strom-PK_Kombi_Easy24 Natur PUR+Natur12 BEST.pdf'},
          { typ: Energiearten.Strom, title: '191201_Strom-PK_Livy Protect_gueltig ab 01.12.2019.pdf', file: '/dokumente/pk/191201_Strom-PK_Livy Protect_gueltig ab 01.12.2019.pdf'},
          { typ: Energiearten.Strom, title: '19006_PK-Waermepumpe.pdf', file: '/dokumente/pk/19006_PK-Waermepumpe.pdf'},
          { typ: Energiearten.Gas, title: '19006_Gas-PK_Kombi_Easy24 Pur+Easy12.pdf', file: '/dokumente/pk/19006_Gas-PK_Kombi_Easy24 Pur+Easy12.pdf'},
          { typ: Energiearten.Gas, title: '191201_Gas-PK_Livy Protect_gueltig ab 01.12.2019.pdf', file: '/dokumente/pk/191201_Gas-PK_Livy Protect_gueltig ab 01.12.2019.pdf'},
        ],
        itemsGK: [
          { typ: Energiearten.Strom, title: '19003_Strom-GK-Profi_Direkt12.pdf', file: '/dokumente/gk/19003_Strom-GK-Profi_Direkt12.pdf'},
          { typ: Energiearten.Strom, title: '19003_Strom-GK_Kombi_Profi XL+Profi Plus.pdf', file: '/dokumente/gk/19003_Strom-GK_Kombi_Profi XL+Profi Plus.pdf'},
          { typ: Energiearten.Strom, title: '19003_Strom-GK_Livy  Protect.pdf', file: '/dokumente/gk/19003_Strom-GK_Livy  Protect.pdf'},
          { typ: Energiearten.Strom, title: '19003_GK-Waermepumpe.pdf', file: '/dokumente/gk/19003_GK-Waermepumpe.pdf'},
          { typ: Energiearten.Gas, title: '19003_Gas-GK_EasyFix12_24.pdf', file: '/dokumente/gk/19003_Gas-GK_EasyFix12_24.pdf'},
          { typ: Energiearten.Gas, title: '19003_Gas-GK_Livy Protect.pdf', file: '/dokumente/gk/19003_Gas-GK_Livy Protect.pdf'},
        ],
        fields: [
          {
            key: 'typ',
            label: 'Energieart'
          },
          {
            key: 'title',
            label: 'Dokument'
          },
          {
            key: 'file',
            label: '',
          }
        ]
      }
    }
  }
</script>
